import React from 'react';
import { Heading } from '../../components';
import { richText } from '../../util/richText';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const SectionTextMaybe = props => {
  const { text, heading, showAsIngress = false, keyType } = props;

  const textClass = showAsIngress ? css.ingress : css.text;
  const content = richText(text, {
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    longWordClass: css.longWord,
    breakChars: '/',
  });
  return text ? (
    <section className={css.sectionText}>
      {heading ? (
        <Heading
          as="h2"
          rootClassName={
            heading === 'Disclaimers & Rules'
              ? css.disclaimerHeading
              : css.sectionHeading
          }
        >
          {heading}
        </Heading>
      ) : null}
      <p className={textClass}>{content}</p>
      {keyType === 'sizeColorOptions' ? (
        <span className={css.sizeColor}>
          <FormattedMessage id="ListingPage.colorSizeMsg" />
        </span>
      ) : null}
    </section>
  ) : null;
};

export default SectionTextMaybe;
